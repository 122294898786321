customForm = {
	baseOptions: {
		useNativeDropOnMobileDevices: false,
		unselectableClass:'customForm-unselectable', 
		labelActiveClass:'customForm-label-active',
		labelDisabledClass:'customForm-label-disabled',
		classPrefix: 'customForm-class-',
		hiddenClass:'customForm-hidden',
		focusClass:'customForm-focus',
		wrapperTag: 'div'
	},
	customForms: {
		setOptions: function(obj) {
			for(var p in obj) {
				if(obj.hasOwnProperty(p) && typeof obj[p] === 'object') {
					customForm.lib.extend(customForm.modules[p].prototype.defaultOptions, obj[p]);
				}
			}
		},
		replaceAll: function() {
			for(var k in customForm.modules) {
				var els = customForm.lib.queryBySelector(customForm.modules[k].prototype.selector);
				for(var i = 0; i<els.length; i++) {
					if(els[i].customForm) {
						// refresh form element state
						els[i].customForm.refreshState();
					} else {
						// replace form element
						if(!customForm.lib.hasClass(els[i], 'default') && customForm.modules[k].prototype.checkElement(els[i])) {
							new customForm.modules[k]({
								replaces:els[i]
							});
						}
					}
				}
			}
		},
		refreshElement: function(obj) {
			if(obj && obj.customForm) {
				obj.customForm.refreshState();
			}
		},
		refreshAll: function() {
			for(var k in customForm.modules) {
				var els = customForm.lib.queryBySelector(customForm.modules[k].prototype.selector);
				for(var i = 0; i<els.length; i++) {
					if(els[i].customForm) {
						// refresh form element state
						els[i].customForm.refreshState();
					}
				}
			}
		},
		destroyAll: function() {
			for(var k in customForm.modules) {
				var els = customForm.lib.queryBySelector(customForm.modules[k].prototype.selector);
				for(var i = 0; i<els.length; i++) {
					if(els[i].customForm) {
						els[i].customForm.destroy();
					}
				}
			}
		}
	},	
	// check device type
	isTouchDevice: (function() {
		try {
			return ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch;
		} catch (e) {
			return false;
		}
	}()),
	// define base module
	setBaseModule: function(obj) {
		customForm.customControl = function(opt){
			this.options = customForm.lib.extend({}, customForm.baseOptions, this.defaultOptions, opt);
			this.init();
		}
		for(var p in obj) {
			customForm.customControl.prototype[p] = obj[p];
		}
	},
	// miscellaneous init
	init: function(){
		this.eventPress = this.isTouchDevice ? 'touchstart' : 'mousedown';
		this.eventMove = this.isTouchDevice ? 'touchmove' : 'mousemove';
		this.eventRelease = this.isTouchDevice ? 'touchend' : 'mouseup';
		return this;
	},
	initStyles: function() {
		var head = document.getElementsByTagName('head')[0],
			rules = document.createTextNode('.'+customForm.baseOptions.unselectableClass+'{'+
				'-moz-user-select:none;'+
				'-webkit-tap-highlight-color:rgba(255,255,255,0);'+
				'-webkit-user-select:none;'+
				'user-select:none;'+
			'}'),
			style = document.createElement('style');
		
		style.type = 'text/css';
		if(style.styleSheet) {
			style.styleSheet.cssText = rules.nodeValue;
		} else {
			style.appendChild(rules);
		}
		head.appendChild(style);
	},
	
	modules: {},
	plugins: {},
	
	// add module to customForm.modules
	addModule: function(obj) {
		if(obj.name){
			// create new module proto class
			customForm.modules[obj.name] = function(){
				customForm.modules[obj.name].superclass.constructor.apply(this, arguments);
			}
			customForm.lib.inherit(customForm.modules[obj.name], customForm.customControl);
			for(var p in obj) {
				customForm.modules[obj.name].prototype[p] = obj[p]
			}
			// on create module
			customForm.modules[obj.name].prototype.onCreateModule();
			// make callback for exciting modules
			for(var mod in customForm.modules) {
				if(customForm.modules[mod] != customForm.modules[obj.name]) {
					customForm.modules[mod].prototype.onModuleAdded(customForm.modules[obj.name]);
				}
			}
		}
	},
	
	// add plugin to customForm.plugins
	addPlugin: function(obj) {
		if(obj && obj.name) {
			customForm.plugins[obj.name] = function() {
				this.init.apply(this, arguments);
			}
			for(var p in obj) {
				customForm.plugins[obj.name].prototype[p] = obj[p];
			}
		}
	}
}.init();

/*
 * Custom Form Control prototype
 */
customForm.setBaseModule({
	init: function(){
		if(this.options.replaces) {
			this.realElement = this.options.replaces;
			this.realElement.customForm = this;
			this.replaceObject();
		}
	},
	defaultOptions: {
		// default module options (will be merged with base options)
	},
	checkElement: function(el){
		return true; // additional check for correct form element
	},
	replaceObject: function(){
		this.createWrapper();
		this.attachEvents();
		this.fixStyles();
		this.setupWrapper();
	},
	createWrapper: function(){
		this.fakeElement = customForm.lib.createElement(this.options.wrapperTag);
		this.labelFor = customForm.lib.getLabelFor(this.realElement);
		customForm.lib.disableTextSelection(this.fakeElement);
		customForm.lib.addClass(this.fakeElement, customForm.lib.getAllClasses(this.realElement.className, this.options.classPrefix));
		customForm.lib.addClass(this.realElement, customForm.baseOptions.hiddenClass);
	},
	attachEvents: function(){
		customForm.lib.event.add(this.realElement, 'focus', this.onFocusHandler, this);
		customForm.lib.event.add(this.realElement, 'blur', this.onBlurHandler, this);
		customForm.lib.event.add(this.fakeElement, 'click', this.onFakeClick, this);
		customForm.lib.event.add(this.fakeElement, customForm.eventPress, this.onFakePressed, this);
		customForm.lib.event.add(this.fakeElement, customForm.eventRelease, this.onFakeReleased, this);

		if(this.labelFor) {
			this.labelFor.customForm = this;
			customForm.lib.event.add(this.labelFor, 'click', this.onFakeClick, this);
			customForm.lib.event.add(this.labelFor, customForm.eventPress, this.onFakePressed, this);
			customForm.lib.event.add(this.labelFor, customForm.eventRelease, this.onFakeReleased, this);
		}
	},
	fixStyles: function() {
		// hide mobile webkit tap effect
		if(customForm.isTouchDevice) {
			var tapStyle = 'rgba(255,255,255,0)';
			this.realElement.style.webkitTapHighlightColor = tapStyle; 
			this.fakeElement.style.webkitTapHighlightColor = tapStyle; 
			if(this.labelFor) {
				this.labelFor.style.webkitTapHighlightColor = tapStyle; 
			}
		}
	},
	setupWrapper: function(){
		// implement in subclass
	},
	refreshState: function(){
		// implement in subclass
	},
	destroy: function() {
		if(this.fakeElement && this.fakeElement.parentNode) {
			this.fakeElement.parentNode.removeChild(this.fakeElement);
		}
		customForm.lib.removeClass(this.realElement, customForm.baseOptions.hiddenClass);
		this.realElement.customForm = null;
	},
	onFocus: function(){
		// emulated focus event
		customForm.lib.addClass(this.fakeElement,this.options.focusClass);
	},
	onBlur: function(cb){
		// emulated blur event
		customForm.lib.removeClass(this.fakeElement,this.options.focusClass);
	},
	onFocusHandler: function() {
		// handle focus loses
		if(this.focused) return;
		this.focused = true;
		
		// handle touch devices also
		if(customForm.isTouchDevice) {
			if(customForm.focusedInstance && customForm.focusedInstance.realElement != this.realElement) {
				customForm.focusedInstance.onBlur();
				customForm.focusedInstance.realElement.blur();
			}
			customForm.focusedInstance = this;
		}
		this.onFocus.apply(this, arguments);
	},
	onBlurHandler: function() {
		// handle focus loses
		if(!this.pressedFlag) {
			this.focused = false;
			this.onBlur.apply(this, arguments);
		}
	},
	onFakeClick: function(){
		if(customForm.isTouchDevice) {
			this.onFocus();
		} else if(!this.realElement.disabled) {
			this.realElement.focus();
		}
	},
	onFakePressed: function(e){
		this.pressedFlag = true;
	},
	onFakeReleased: function(){
		this.pressedFlag = false;
	},
	onCreateModule: function(){
		// implement in subclass
	},
	onModuleAdded: function(module) {
		// implement in subclass
	},
	onControlReady: function() {
		// implement in subclass
	}
});

// customForm lib
customForm.lib = {
	bind: function(func, scope){
		return function() {
			return func.apply(scope, arguments);
		}
	},
	browser: (function() {
		var ua = navigator.userAgent.toLowerCase(), res = {},
		match = /(webkit)[ \/]([\w.]+)/.exec(ua) || /(opera)(?:.*version)?[ \/]([\w.]+)/.exec(ua) ||
				/(msie) ([\w.]+)/.exec(ua) || ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+))?/.exec(ua) || [];
		res[match[1]] = true;
		res.version = match[2] || "0";
		res.safariMac = ua.indexOf('mac') != -1 && ua.indexOf('safari') != -1;
		return res;
	})(),
	getOffset: function (obj) {
		if (obj.getBoundingClientRect) {
			var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft;
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			var clientLeft = document.documentElement.clientLeft || document.body.clientLeft || 0;
			var clientTop = document.documentElement.clientTop || document.body.clientTop || 0;
			return {
				top:Math.round(obj.getBoundingClientRect().top + scrollTop - clientTop),
				left:Math.round(obj.getBoundingClientRect().left + scrollLeft - clientLeft)
			}
		} else {
			var posLeft = 0, posTop = 0;
			while (obj.offsetParent) {posLeft += obj.offsetLeft; posTop += obj.offsetTop; obj = obj.offsetParent;}
			return {top:posTop,left:posLeft};
		}
	},
	getScrollTop: function() {
		return window.pageYOffset || document.documentElement.scrollTop;
	},
	getScrollLeft: function() {
		return window.pageXOffset || document.documentElement.scrollLeft;
	},
	getWindowWidth: function(){
		return document.compatMode=='CSS1Compat' ? document.documentElement.clientWidth : document.body.clientWidth;
	},
	getWindowHeight: function(){
		return document.compatMode=='CSS1Compat' ? document.documentElement.clientHeight : document.body.clientHeight;
	},
	getStyle: function(el, prop) {
		if (document.defaultView && document.defaultView.getComputedStyle) {
			return document.defaultView.getComputedStyle(el, null)[prop];
		} else if (el.currentStyle) {
			return el.currentStyle[prop];
		} else {
			return el.style[prop];
		}
	},
	getParent: function(obj, selector) {
		while(obj.parentNode && obj.parentNode != document.body) {
			if(obj.parentNode.tagName.toLowerCase() == selector.toLowerCase()) {
				return obj.parentNode;
			}
			obj = obj.parentNode;
		}
		return false;
	},
	isParent: function(child, parent) {
		while(child.parentNode) {
			if(child.parentNode === parent) {
				return true;
			}
			child = child.parentNode;
		}
		return false;
	},
	getLabelFor: function(object) {
		if(customForm.lib.getParent(object,'label')) {
			return object.parentNode;
		} else if(object.id) {
			return customForm.lib.queryBySelector('label[for="' + object.id + '"]')[0];
		}
	},
	disableTextSelection: function(el){
		if (typeof el.onselectstart !== 'undefined') {
			el.onselectstart = function() {return false};
		} else if(window.opera) {
			el.setAttribute('unselectable', 'on');
		} else {
			customForm.lib.addClass(el, customForm.baseOptions.unselectableClass);
		}
	},
	enableTextSelection: function(el) {
		if (typeof el.onselectstart !== 'undefined') {
			el.onselectstart = null;
		} else if(window.opera) {
			el.removeAttribute('unselectable');
		} else {
			customForm.lib.removeClass(el, customForm.baseOptions.unselectableClass);
		}
	},
	queryBySelector: function(selector, scope){
		return this.getElementsBySelector(selector, scope);
	},
	prevSibling: function(node) {
		while(node = node.previousSibling) if(node.nodeType == 1) break;
		return node;
	},
	nextSibling: function(node) {
		while(node = node.nextSibling) if(node.nodeType == 1) break;
		return node;
	},
	fireEvent: function(element,event) {
		 if (document.createEvent){
		   var evt = document.createEvent('HTMLEvents');
		   evt.initEvent(event, true, true );
		   return !element.dispatchEvent(evt);
		  }
		  else{
		   var evt = document.createEventObject();
		   return element.fireEvent('on'+event,evt)
		  }  
	},
	isParent: function(p, c) {
		while(c.parentNode) {
			if(p == c) {
				return true;
			}
			c = c.parentNode;
		}
		return false;
	},
	inherit: function(Child, Parent) {
		var F = function() { }
		F.prototype = Parent.prototype
		Child.prototype = new F()
		Child.prototype.constructor = Child
		Child.superclass = Parent.prototype
	},
	extend: function(obj) {
		for(var i = 1; i < arguments.length; i++) {
			for(var p in arguments[i]) {
				if(arguments[i].hasOwnProperty(p)) {
					obj[p] = arguments[i][p];
				}
			}
		}
		return obj;
	},
	hasClass: function (obj,cname) {
		return (obj.className ? obj.className.match(new RegExp('(\\s|^)'+cname+'(\\s|$)')) : false);
	},
	addClass: function (obj,cname) {
		if (!this.hasClass(obj,cname)) obj.className += (!obj.className.length || obj.className.charAt(obj.className.length - 1) === ' ' ? '' : ' ') + cname;
	},
	removeClass: function (obj,cname) {
		if (this.hasClass(obj,cname)) obj.className=obj.className.replace(new RegExp('(\\s|^)'+cname+'(\\s|$)'),' ').replace(/\s+$/, '');
	},
	toggleClass: function(obj, cname, condition) {
		if(condition) this.addClass(obj, cname); else this.removeClass(obj, cname);
	},
	createElement: function(tagName, options) {
		var el = document.createElement(tagName);
		for(var p in options) {
			if(options.hasOwnProperty(p)) {
				switch (p) {
					case 'class': el.className = options[p]; break;
					case 'html': el.innerHTML = options[p]; break;
					case 'style': this.setStyles(el, options[p]); break;
					default: el.setAttribute(p, options[p]);
				}
			}
		}
		return el;
	},
	setStyles: function(el, styles) {
		for(var p in styles) {
			if(styles.hasOwnProperty(p)) {
				switch (p) {
					case 'float': el.style.cssFloat = styles[p]; break;
					case 'opacity': el.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(opacity='+styles[p]*100+')'; el.style.opacity = styles[p]; break;
					default: el.style[p] = (typeof styles[p] === 'undefined' ? 0 : styles[p]) + (typeof styles[p] === 'number' ? 'px' : '');
				}
			}
		}
		return el;
	},
	getInnerWidth: function(el) {
		return el.offsetWidth - (parseInt(this.getStyle(el,'paddingLeft')) || 0) - (parseInt(this.getStyle(el,'paddingRight')) || 0);
	},
	getInnerHeight: function(el) {
		return el.offsetHeight - (parseInt(this.getStyle(el,'paddingTop')) || 0) - (parseInt(this.getStyle(el,'paddingBottom')) || 0);
	},
	getAllClasses: function(cname, prefix, skip) {
		if(!skip) skip = '';
		if(!prefix) prefix = '';
		return cname ? cname.replace(new RegExp('(\\s|^)'+skip+'(\\s|$)'),' ').replace(/[\s]*([\S]+)+[\s]*/gi,prefix+"$1 ") : '';
	},
	getElementsBySelector: function(selector, scope) {
		if(typeof document.querySelectorAll === 'function') {
			return (scope || document).querySelectorAll(selector);
		}
		var selectors = selector.split(',');
		var resultList = [];
		for(var s = 0; s < selectors.length; s++) {
			var currentContext = [scope || document];
			var tokens = selectors[s].replace(/^\s+/,'').replace(/\s+$/,'').split(' ');
			for (var i = 0; i < tokens.length; i++) {
				token = tokens[i].replace(/^\s+/,'').replace(/\s+$/,'');
				if (token.indexOf('#') > -1) {
					var bits = token.split('#'), tagName = bits[0], id = bits[1];
					var element = document.getElementById(id);
					if (tagName && element.nodeName.toLowerCase() != tagName) {
						return [];
					}
					currentContext = [element];
					continue;
				}
				if (token.indexOf('.') > -1) {
					var bits = token.split('.'), tagName = bits[0] || '*', className = bits[1], found = [], foundCount = 0;
					for (var h = 0; h < currentContext.length; h++) {
						var elements;
						if (tagName == '*') {
							elements = currentContext[h].getElementsByTagName('*');
						} else {
							elements = currentContext[h].getElementsByTagName(tagName);
						}
						for (var j = 0; j < elements.length; j++) {
							found[foundCount++] = elements[j];
						}
					}
					currentContext = [];
					var currentContextIndex = 0;
					for (var k = 0; k < found.length; k++) {
						if (found[k].className && found[k].className.match(new RegExp('(\\s|^)'+className+'(\\s|$)'))) {
							currentContext[currentContextIndex++] = found[k];
						}
					}
					continue;
				}
				if (token.match(/^(\w*)\[(\w+)([=~\|\^\$\*]?)=?"?([^\]"]*)"?\]$/)) {
					var tagName = RegExp.$1 || '*', attrName = RegExp.$2, attrOperator = RegExp.$3, attrValue = RegExp.$4;
					if(attrName.toLowerCase() == 'for' && this.browser.msie && this.browser.version < 8) {
						attrName = 'htmlFor';
					}
					var found = [], foundCount = 0;
					for (var h = 0; h < currentContext.length; h++) {
						var elements;
						if (tagName == '*') {
							elements = currentContext[h].getElementsByTagName('*');
						} else {
							elements = currentContext[h].getElementsByTagName(tagName);
						}
						for (var j = 0; elements[j]; j++) {
							found[foundCount++] = elements[j];
						}
					}
					currentContext = [];
					var currentContextIndex = 0, checkFunction;
					switch (attrOperator) {
						case '=': checkFunction = function(e) { return (e.getAttribute(attrName) == attrValue) }; break;
						case '~': checkFunction = function(e) { return (e.getAttribute(attrName).match(new RegExp('(\\s|^)'+attrValue+'(\\s|$)'))) }; break;
						case '|': checkFunction = function(e) { return (e.getAttribute(attrName).match(new RegExp('^'+attrValue+'-?'))) }; break;
						case '^': checkFunction = function(e) { return (e.getAttribute(attrName).indexOf(attrValue) == 0) }; break;
						case '$': checkFunction = function(e) { return (e.getAttribute(attrName).lastIndexOf(attrValue) == e.getAttribute(attrName).length - attrValue.length) }; break;
						case '*': checkFunction = function(e) { return (e.getAttribute(attrName).indexOf(attrValue) > -1) }; break;
						default : checkFunction = function(e) { return e.getAttribute(attrName) };
					}
					currentContext = [];
					var currentContextIndex = 0;
					for (var k = 0; k < found.length; k++) {
						if (checkFunction(found[k])) {
							currentContext[currentContextIndex++] = found[k];
						}
					}
					continue;
				}
				tagName = token;
				var found = [], foundCount = 0;
				for (var h = 0; h < currentContext.length; h++) {
					var elements = currentContext[h].getElementsByTagName(tagName);
					for (var j = 0; j < elements.length; j++) {
						found[foundCount++] = elements[j];
					}
				}
				currentContext = found;
			}
			resultList = [].concat(resultList,currentContext);
		}
		return resultList;
	},
	scrollSize: (function(){
		var content, hold, sizeBefore, sizeAfter;
		function buildSizer(){
			if(hold) removeSizer();
			content = document.createElement('div');
			hold = document.createElement('div');
			hold.style.cssText = 'position:absolute;overflow:hidden;width:100px;height:100px';
			hold.appendChild(content);
			document.body.appendChild(hold);
		}
		function removeSizer(){
			document.body.removeChild(hold);
			hold = null;
		}
		function calcSize(vertical) {
			buildSizer();
			content.style.cssText = 'height:'+(vertical ? '100%' : '200px');
			sizeBefore = (vertical ? content.offsetHeight : content.offsetWidth);
			hold.style.overflow = 'scroll'; content.innerHTML = 1;
			sizeAfter = (vertical ? content.offsetHeight : content.offsetWidth);
			if(vertical && hold.clientHeight) sizeAfter = hold.clientHeight;
			removeSizer();
			return sizeBefore - sizeAfter;
		}
		return {
			getWidth:function(){
				return calcSize(false);
			},
			getHeight:function(){
				return calcSize(true)
			}
		}
	}()),
	domReady: function (handler){
		var called = false
		function ready() {
			if (called) return;
			called = true;
			handler();
		}
		if (document.addEventListener) {
			document.addEventListener("DOMContentLoaded", ready, false);
		} else if (document.attachEvent) {
			if (document.documentElement.doScroll && window == window.top) {
				function tryScroll(){
					if (called) return
					if (!document.body) return
					try {
						document.documentElement.doScroll("left")
						ready()
					} catch(e) {
						setTimeout(tryScroll, 0)
					}
				}
				tryScroll()
			}
			document.attachEvent("onreadystatechange", function(){
				if (document.readyState === "complete") {
					ready()
				}
			})
		}
		if (window.addEventListener) window.addEventListener('load', ready, false)
		else if (window.attachEvent) window.attachEvent('onload', ready)
	},
	event: (function(){
		var guid = 0;
		function fixEvent(e) {
			e = e || window.event;
			if (e.isFixed) {
				return e;
			}
			e.isFixed = true; 
			e.preventDefault = e.preventDefault || function(){this.returnValue = false}
			e.stopPropagation = e.stopPropagaton || function(){this.cancelBubble = true}
			if (!e.target) {
				e.target = e.srcElement
			}
			if (!e.relatedTarget && e.fromElement) {
				e.relatedTarget = e.fromElement == e.target ? e.toElement : e.fromElement;
			}
			if (e.pageX == null && e.clientX != null) {
				var html = document.documentElement, body = document.body;
				e.pageX = e.clientX + (html && html.scrollLeft || body && body.scrollLeft || 0) - (html.clientLeft || 0);
				e.pageY = e.clientY + (html && html.scrollTop || body && body.scrollTop || 0) - (html.clientTop || 0);
			}
			if (!e.which && e.button) {
				e.which = e.button & 1 ? 1 : (e.button & 2 ? 3 : (e.button & 4 ? 2 : 0));
			}
			if(e.type === "DOMMouseScroll" || e.type === 'mousewheel') {
				e.mWheelDelta = 0;
				if (e.wheelDelta) {
					e.mWheelDelta = e.wheelDelta/120;
				} else if (e.detail) {
					e.mWheelDelta = -e.detail/3;
				}
			}
			return e;
		}
		function commonHandle(event, customScope) {
			event = fixEvent(event);
			var handlers = this.events[event.type];
			for (var g in handlers) {
				var handler = handlers[g];
				var ret = handler.call(customScope || this, event);
				if (ret === false) {
					event.preventDefault()
					event.stopPropagation()
				}
			}
		}
		var publicAPI = {
			add: function(elem, type, handler, forcedScope) {
				if (elem.setInterval && (elem != window && !elem.frameElement)) {
					elem = window;
				}
				if (!handler.guid) {
					handler.guid = ++guid;
				}
				if (!elem.events) {
					elem.events = {};
					elem.handle = function(event) {
						return commonHandle.call(elem, event);
					}
				}
				if (!elem.events[type]) {
					elem.events[type] = {};
					if (elem.addEventListener) elem.addEventListener(type, elem.handle, false);
					else if (elem.attachEvent) elem.attachEvent("on" + type, elem.handle);
					if(type === 'mousewheel') {
						publicAPI.add(elem, 'DOMMouseScroll', handler, forcedScope);
					}
				}
				var fakeHandler = customForm.lib.bind(handler, forcedScope);
				fakeHandler.guid = handler.guid;
				elem.events[type][handler.guid] = forcedScope ? fakeHandler : handler;
			},
			remove: function(elem, type, handler) {
				var handlers = elem.events && elem.events[type];
				if (!handlers) return;
				delete handlers[handler.guid];
				for(var any in handlers) return;
				if (elem.removeEventListener) elem.removeEventListener(type, elem.handle, false);
				else if (elem.detachEvent) elem.detachEvent("on" + type, elem.handle);
				delete elem.events[type];
				for (var any in elem.events) return;
				try {
					delete elem.handle;
					delete elem.events;
				} catch(e) {
					if(elem.removeAttribute) {
						elem.removeAttribute("handle");
						elem.removeAttribute("events");
					}
				}
				if(type === 'mousewheel') {
					publicAPI.remove(elem, 'DOMMouseScroll', handler);
				}
			}
		}
		return publicAPI;
	}())
}

// init customForm styles
customForm.lib.domReady(function(){
	customForm.initStyles();
});

// custom checkbox module
customForm.addModule({
  name:'checkbox',
  selector:'input[type="checkbox"]',
  defaultOptions: {
    wrapperClass:'chk-area',
    focusClass:'chk-focus',
    checkedClass:'chk-checked',
    labelActiveClass:'chk-label-active',
    uncheckedClass:'chk-unchecked',
    disabledClass:'chk-disabled',
    chkStructure:'<span></span>'
  },
  setupWrapper: function(){
    customForm.lib.addClass(this.fakeElement, this.options.wrapperClass);
    this.fakeElement.innerHTML = this.options.chkStructure;
    this.realElement.parentNode.insertBefore(this.fakeElement, this.realElement);
    customForm.lib.event.add(this.realElement, 'click', this.onRealClick, this);
    this.refreshState();
  },
  onFakePressed: function() {
    customForm.modules[this.name].superclass.onFakePressed.apply(this, arguments);
    if(!this.realElement.disabled) {
      this.realElement.focus();
    }
  },
  onFakeClick: function(e) {
    customForm.modules[this.name].superclass.onFakeClick.apply(this, arguments);
    this.tmpTimer = setTimeout(customForm.lib.bind(function(){
      this.toggle();
    },this),10);
    return false;
  },
  onRealClick: function(e) {
    setTimeout(customForm.lib.bind(function(){
      this.refreshState();
    },this),10);
    e.stopPropagation();
  },
  toggle: function(e){
    if(!this.realElement.disabled) {
      if(this.realElement.checked) {
        this.realElement.checked = false;
      } else {
        this.realElement.checked = true;
      }
      customForm.lib.fireEvent(this.realElement, 'change');
    }
    this.refreshState();
    return false;
  },
  refreshState: function(){
    if(this.realElement.checked) {
      customForm.lib.addClass(this.fakeElement, this.options.checkedClass);
      customForm.lib.removeClass(this.fakeElement, this.options.uncheckedClass);
      if(this.labelFor) {
        customForm.lib.addClass(this.labelFor, this.options.labelActiveClass);
      }
    } else {
      customForm.lib.removeClass(this.fakeElement, this.options.checkedClass);
      customForm.lib.addClass(this.fakeElement, this.options.uncheckedClass);
      if(this.labelFor) {
        customForm.lib.removeClass(this.labelFor, this.options.labelActiveClass);
      }
    }
    if(this.realElement.disabled) {
      customForm.lib.addClass(this.fakeElement, this.options.disabledClass);
      if(this.labelFor) {
        customForm.lib.addClass(this.labelFor, this.options.labelDisabledClass);
      }
    } else {
      customForm.lib.removeClass(this.fakeElement, this.options.disabledClass);
      if(this.labelFor) {
        customForm.lib.removeClass(this.labelFor, this.options.labelDisabledClass);
      }
    }
  }
});
// custom radio module
customForm.addModule({
  name:'radio',
  selector: 'input[type="radio"]',
  defaultOptions: {
    wrapperClass:'rad-area',
    focusClass:'rad-focus',
    checkedClass:'rad-checked',
    uncheckedClass:'rad-unchecked',
    disabledClass:'rad-disabled',
    radStructure:'<span></span>'
  },
  getRadioGroup: function(item){
    var name = item.getAttribute('name');
    if(name) {
      return customForm.lib.queryBySelector('input[name="'+name+'"]', customForm.lib.getParent('form'));
    } else {
      return [item];
    }
  },
  setupWrapper: function(){
    customForm.lib.addClass(this.fakeElement, this.options.wrapperClass);
    this.fakeElement.innerHTML = this.options.radStructure;
    this.realElement.parentNode.insertBefore(this.fakeElement, this.realElement);
    this.refreshState();
    this.addEvents();
  },
  addEvents: function(){
    customForm.lib.event.add(this.fakeElement, 'click', this.toggleRadio, this);
    if(this.labelFor) {
      customForm.lib.event.add(this.labelFor, 'click', this.toggleRadio, this);
    }
  },
  onFocus: function(e) {
    customForm.modules[this.name].superclass.onFocus.apply(this, arguments);
    setTimeout(customForm.lib.bind(function(){
      this.refreshState();
    },this),10);
  },
  toggleRadio: function(){
    if(!this.realElement.disabled) {
      this.realElement.checked = true;
    }
    this.refreshState();
    customForm.lib.fireEvent(this.realElement, 'change');
  },
  refreshState: function(){
    var els = this.getRadioGroup(this.realElement);
    for(var i = 0; i < els.length; i++) {
      var curEl = els[i].customForm;
      if(curEl) {
        if(curEl.realElement.checked) {
          customForm.lib.addClass(curEl.fakeElement, curEl.options.checkedClass);
          customForm.lib.removeClass(curEl.fakeElement, curEl.options.uncheckedClass);
          if(curEl.labelFor) {
            customForm.lib.addClass(curEl.labelFor, curEl.options.labelActiveClass);
          }
        } else {
          customForm.lib.removeClass(curEl.fakeElement, curEl.options.checkedClass);
          customForm.lib.addClass(curEl.fakeElement, curEl.options.uncheckedClass);
          if(curEl.labelFor) {
            customForm.lib.removeClass(curEl.labelFor, curEl.options.labelActiveClass);
          }
        }
        if(curEl.realElement.disabled) {
          customForm.lib.addClass(curEl.fakeElement, curEl.options.disabledClass);
          if(curEl.labelFor) {
            customForm.lib.addClass(curEl.labelFor, curEl.options.labelDisabledClass);
          }
        } else {
          customForm.lib.removeClass(curEl.fakeElement, curEl.options.disabledClass);
          if(curEl.labelFor) {
            customForm.lib.removeClass(curEl.labelFor, curEl.options.labelDisabledClass);
          }
        }
      }
    }
  }
});